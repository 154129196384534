
const getFieldTypes = () => {
  return [
    {value: 'String'},
    {value: 'Date'},
    {value: 'Number'}
  ]
}


export default {
  getFieldTypes
}

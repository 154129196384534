<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.variables.add.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="key"
        :label="lang.variables.add.key[languageSelected]"
        :disabled="saving"
      ></vs-input> 
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="label"
        :label="lang.variables.add.label[languageSelected]"
        :disabled="saving"
      ></vs-input> 
      <label for="fieldType" class="vs-input--label">
        {{ lang.variables.add.fieldType[languageSelected] }}
      </label>
      <multiselect
        id="fieldType"
        v-model="fieldType"
        :placeholder="lang.variables.add.fieldType[languageSelected]"
        tagPlaceholder=""
        :show-labels="false"
        label="label"
        track-by="value"
        :options="getFieldTypes"
      />      
      <label for="dataType" class="vs-input--label">
        {{ lang.variables.add.dataType[languageSelected] }}
      </label>
      <multiselect
        id="dataType"
        v-model="dataType"
        :placeholder="lang.variables.add.dataType[languageSelected]"
        tagPlaceholder=""
        :show-labels="false"
        label="label"
        track-by="value"
        :options="getDataTypes"
      />

      <vs-divider />
      <vs-button class="float-right" @click="save" :disabled="saving">
        {{ lang.variables.add.saveText[languageSelected] }}
      </vs-button>
      <vs-button
        class="float-right mr-4"
        color="danger"
        @click="cancel"
        :disabled="saving"
      >
        {{ lang.variables.add.cancelText[languageSelected] }}
      </vs-button> 
    </div>
  </vs-popup>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import fieldTypeService from '../../../services/fieldType.service.js'
import dataTypeService from '../../../services/dataType.service.js'
import variableService from '../../../services/variable.service.js'

export default {
  name: 'AddVariable',
  props: {
    version: String,
    service: String
  },
  data() {
    return {
      activePopup: false,
      saving: false,
      key: null,
      label: null,
      fieldType: null,
      dataType: null
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'variables']),
    getFieldTypes() {
      return fieldTypeService.getFieldTypes().map(f => {
        return {
          value: f.value,
          label: this.lang.variables.fieldType.options[f.value.toLowerCase()][this.languageSelected]
        }
      })
    },
    getDataTypes() {
      return dataTypeService.getDataTypes().map(d => {
        return {
          value: d.value,
          label: this.lang.variables.dataType.options[d.value.toLowerCase()][this.languageSelected]
        }
      })
    }
  },
  methods: {
    // ...mapMutations('botMaker', ['START_STORE', 'SET_BOT_ID']),
    ...mapMutations('botMaker', ['UNSHIFT_VARIABLE']),
    openPopup() {
      this.activePopup = true
    },
    cancel() {
      this.activePopup = false
      // clean form 
      this.key = null
      this.label = null
      this.fieldType = null
      this.dataType = null
    },
    _isUniqueKey() {
        if(this.variables.filter(variable => variable.key == this.key).length == 0) {
          return true
        } else {
            return this.$vs.notify({
            title: this.lang.variables.validationErrors.duplicatedKey.title[
              this.languageSelected
            ],
            text: this.lang.variables.validationErrors.duplicatedKey.text[
              this.languageSelected
            ],
            color: 'warning'
          })
        }
    },
    _isValidKey() {
      if(!this.key || this.key.trim().length === 0 || this.key.trim().length > 255) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidKey.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidKey.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    _isValidLabel() {
      if(!this.label || this.label.trim().length === 0 || this.label.trim().length > 255) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidLabel.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidLabel.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    _isValidFieldType() {
      if(!this.fieldType || !this.fieldType.value || this.fieldType.value.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidFieldType.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidFieldType.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    _isValidDataType() {
      if(!this.dataType || !this.dataType.value || this.dataType.value.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidDataType.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidDataType.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    async save() {
      if(this._isValidKey() && this._isValidLabel() && this._isValidDataType() && this._isValidFieldType() && this._isUniqueKey()) {
        try {
          const newVariable = {  
            key: this.key,
            label: this.label,
            fieldType: this.fieldType.value,
            dataType: this.dataType.value,
            version: this.version
          };
          this.saving = true
          const result = await variableService.createVariable(newVariable)
          this.$vs.notify({
            title: this.lang.variables.add.success.title[this.languageSelected],
            text: this.lang.variables.add.success.text[this.languageSelected],
            color: 'success'
          })
          this.UNSHIFT_VARIABLE(result.data)
          this.cancel()
        } catch (error) {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data[0] &&
            error.response.data[0].message
          ) {
            this.$vs.notify({
              title: '400',
              text: error.response.data[0].message,
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: this.lang.variables.add.error.title[this.languageSelected],
              text: this.lang.variables.add.error.text[this.languageSelected],
              color: 'warning'
            })
          }
        }
      this.saving = false
      } else {
        return
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .multiselect {
    min-width: 250px;
    padding-bottom: 30px !important;
    background: transparent;
    width: 100% !important;
    &--disabled {
      .multiselect__select {
        height: 42px;
      }
    }
    .multiselect__content-wrapper{
      max-height: 150px !important;
    }
  }
</style>
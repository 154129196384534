<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.variables.edit.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="key"
        :label="lang.variables.add.key[languageSelected]"
      ></vs-input> 
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="label"
        :label="lang.variables.add.label[languageSelected]"
      ></vs-input> 
      <label for="fieldType" class="vs-input--label">
        {{ lang.variables.add.fieldType[languageSelected] }}
      </label>
      <multiselect
        id="fieldType"
        v-model="fieldType"
        :placeholder="lang.variables.add.fieldType[languageSelected]"
        tagPlaceholder=""
        :show-labels="false"
        label="label"
        track-by="value"
        :options="getFieldTypes"
      />      
      <label for="dataType" class="vs-input--label">
        {{ lang.variables.add.dataType[languageSelected] }}
      </label>
      <multiselect
        id="dataType"
        v-model="dataType"
        :placeholder="lang.variables.add.dataType[languageSelected]"
        tagPlaceholder=""
        :show-labels="false"
        label="label"
        track-by="value"
        :options="getDataTypes"
      />

      <vs-divider />
      <vs-button
        class="float-right"
        icon-pack="feather"
        icon="icon-save"
        @click="save"
        :disabled="saving"
      >
        {{ this.lang.variables.edit.saveText[this.languageSelected] }}
      </vs-button>
      <vs-button
        type="flat"
        class="float-right mr-4"
        icon-pack="feather"
        icon="icon-x"
        color="danger"
        @click="cancel"
        :disabled="saving"
      >
        {{ this.lang.variables.edit.cancelText[this.languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { errorHandler } from '../../../services/ErrorHandler'
import fieldTypeService from '../../../services/fieldType.service.js'
import dataTypeService from '../../../services/dataType.service.js'
import variableService from '../../../services/variable.service.js'

export default {
  name: 'EditVariable',
  props: {
    variable: Object
  },
  data() {
    return {
      activePopup: false,
      saving: false,
      key: null,
      label: null,
      fieldType: null,
      dataType: null,
      id: null
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'variables']),
    getFieldTypes() {
      return fieldTypeService.getFieldTypes().map(f => {
        return {
          value: f.value,
          label: this.lang.variables.fieldType.options[f.value.toLowerCase()][this.languageSelected]
        }
      })
    },
    getDataTypes() {
      return dataTypeService.getDataTypes().map(d => {
        return {
          value: d.value,
          label: this.lang.variables.dataType.options[d.value.toLowerCase()][this.languageSelected]
        }
      })
    }
  },
  watch: {},
  methods: {
    ...mapMutations('botMaker', ['UPDATE_VARIABLE']),
    openPopup() {
      this.activePopup = true
      this.updateFields(this.variable)
    },
    updateFields(newVariable) {
      this.key = newVariable.key
      this.label = newVariable.label
      this.dataType = {
        value: newVariable.dataType,
        label: this.lang.variables.dataType.options[newVariable.dataType.toLowerCase()][this.languageSelected]
      } 
      this.fieldType = {
        value: newVariable.fieldType,
        label: this.lang.variables.fieldType.options[newVariable.fieldType.toLowerCase()][this.languageSelected]
      } 
      this._id = newVariable._id
    },
    cancel() {
      this.activePopup = false
    },

    _isUniqueKey() {
        if(this.variables.filter(variable => variable.key == this.key && variable._id != this._id ).length == 0) {
          return true
        } else {
            return this.$vs.notify({
            title: this.lang.variables.validationErrors.duplicatedKey.title[
              this.languageSelected
            ],
            text: this.lang.variables.validationErrors.duplicatedKey.text[
              this.languageSelected
            ],
            color: 'warning'
          })
        }
    },
    _isValidKey() {
      if(!this.key || this.key.trim().length === 0 || this.key.trim().length > 255) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidKey.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidKey.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    _isValidLabel() {
      if(!this.label || this.label.trim().length === 0 || this.label.trim().length > 255) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidLabel.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidLabel.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    _isValidFieldType() {
      if(!this.fieldType || !this.fieldType.value || this.fieldType.value.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidFieldType.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidFieldType.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    _isValidDataType() {
      if(!this.dataType || !this.dataType.value || this.dataType.value.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.variables.validationErrors.invalidDataType.title[
            this.languageSelected
          ],
          text: this.lang.variables.validationErrors.invalidDataType.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      } else {
        return true
      }
    },
    async save() {
      if(this._isValidKey() && this._isValidLabel() && this._isValidDataType() && this._isValidFieldType() && this._isUniqueKey()) {
        this.saving = true
        try {
          const updatedVariable = {
            key: this.key,
            label: this.label,
            fieldType: this.fieldType.value,
            dataType: this.dataType.value,
            version: this.version
          }
          const result = await variableService.updateVariable(this.variable._id, updatedVariable)
          this.$vs.notify({
            title: this.lang.variables.edit.success.title[this.languageSelected],
            text: this.lang.variables.edit.success.text[this.languageSelected],
            color: 'success',
            position: 'top-right'
          })
          this.UPDATE_VARIABLE(result.data)
          this.cancel()
        } catch (error) {
          if (error && error.response && error.response.data[0]) {
            errorHandler(error.response.data[0])
          }
        }
        this.saving = false
      } else {
        return
      }
    }
  }
}
</script>

<template>
  <div id="variables-table">
    <vs-table
      max-items="4"
      pagination
      :search="true"
      :data="variables"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th sort-key="key">{{
          lang.variables.key[languageSelected]
        }}</vs-th>
        <vs-th sort-key="label">{{
          lang.variables.label[languageSelected]
        }}</vs-th>
        <vs-th sort-key="fieldType">{{
          lang.variables.fieldType[languageSelected]
        }}</vs-th>
        <vs-th sort-key="dataType">{{
          lang.variables.dataType[languageSelected]
        }}</vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="tr.id" v-for="tr in data">
          <vs-td :data="tr.key">{{ tr.key }}</vs-td>
          <vs-td :data="tr.label" :class="{ 'var-sensitive-Info': tr.dataType == 'Sensitive', 'var-normal-Info': tr.dataType == 'Normal'}">{{ tr.label }}</vs-td>
          <vs-td :data="tr.fieldType.value">{{ lang.variables.fieldType.options[tr.fieldType.toLowerCase()][languageSelected] }}</vs-td>
          <vs-td :data="tr.dataType.value">{{ lang.variables.dataType.options[tr.dataType.toLowerCase()][languageSelected] }}</vs-td>
          <vs-td>
            <div class="action-buttons">
              <vs-button
                class="with-space"
                type="flat"
                @click="edit(tr)"
                icon="create"
              ></vs-button>
              <vs-button
                type="flat"
                @click="remove(tr)"
                icon-pack="feather"
                icon="icon-trash"
              ></vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'

import variableService from '../../../services/variable.service.js'

export default {
  name: 'VariablesTable',
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  props: {
    toggleVariableEdit: Function
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'variables'])
  },
  methods: {
    ...mapMutations('botMaker', ['SPLICE_VARIABLE']),
    edit(tr) {
      this.toggleVariableEdit(tr)
    },
    async doRemove(variable) {
      this.$vs.loading()
      try {
        await variableService.deleteVariable(variable._id)
        this.SPLICE_VARIABLE(variable._id)
        this.$vs.notify({
          title: this.lang.variables.remove.success.title[
            this.languageSelected
          ],
          text: this.lang.variables.remove.success.text[
            this.languageSelected
          ],
          color: 'success',
          position: 'top-right'
        })
        this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          title: this.lang.variables.remove.error.title[
            this.languageSelected
          ],
          text: this.lang.variables.remove.error.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    remove: function(variable) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.variables.remove.dialog.title[this.languageSelected],
        text: this.lang.variables.remove.dialog.text[this.languageSelected],
        accept: async () => this.doRemove(variable)
      })
    }
  }
}
</script>

<style lang="scss">
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
#variables-table {
  .vs-table--header {
    justify-content: flex-end;
  }
}
</style>

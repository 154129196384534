
const getDataTypes = () => {
  return [
    {value: 'Normal'},
    {value: 'Sensitive'}
  ]
}


export default {
  getDataTypes
}
